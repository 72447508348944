import * as React from "react";
import styles from "../../styles/functions.module.scss";
import Button from "@mui/material/Button";
import PercentIcon from "@mui/icons-material/Percent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FunctionsIcon from "@mui/icons-material/Functions";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { sendRequest } from "../../utils/api";
import { DataFunctionsWithFilters } from "../../models/types";
import FiltersRow from "../FiltersRow";

/**
 * currently only want few columns so hardcoding
 */
const filterColumns = [
  { column_name: "year" },
  { column_name: "make" },
  { column_name: "model" },
  { column_name: "gearbox" },
  { column_name: "sold" },
] as any;
interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (isSuccess: boolean) => void;
  columns: DataFunctionsWithFilters[];
}
export interface Filter {
  column_name: string;
  values: any[];
  index: number;
}
export interface FunctionData {
  id?: number | string;
  name?: string;
  type: "increase" | "decrease" | "";
  column_to_update: string;
  value: number;
  filters: Filter[];
}
const AddFunctionModal = ({ open, onClose, columns, onSave }: Props) => {
  const [colDataForFilters, setColDataForFilters] = React.useState<
    DataFunctionsWithFilters[]
  >([]);
  const [carvanData, setCarvanData] = React.useState([]);
  const [error, setError] = useState({ name: "", message: "" });
  const [data, setData] = useState<FunctionData>({
    type: "increase",
    column_to_update: "",
    value: 5,
    filters: [
      {
        column_name: "",
        index: 0,
        values: [],
      },
    ],
  });
  const handleClose = () => onClose();
  /**
   * Called when a user click on Save Function Button
   * @returns void
   */
  const saveFunction = async () => {
    setError({
      name: "",
      message: "",
    });
    if (data.column_to_update === "") {
      setError({
        name: "column_to_update",
        message: "Please fill out the form to save",
      });
      return;
    }
    if (!isValueOkay()) {
      setError({
        name: "value",
        message: "Please provide a valid value for function",
      });

      return;
    }
    if (data.filters) {
      for (let filter of data.filters) {
        if (!isFilterOkay(filter)) {
          setError({
            name: "filters",
            message: "Please provide both values for filter",
          });
          return false;
        }
      }
    }
    const payload = {
      function: {
        name: "function",
        type: data.type,
        column_to_update: data.column_to_update,
        value: data.value,
      },
      filters: data.filters,
    };
    const response = await sendRequest(
      "POST",
      "functions/",
      JSON.stringify(payload)
    );
    if (response && response.status === "success") {
      onSave(true);
    } else {
      onSave(false);
    }
    onClose();
  };
  //check data
  const isValueOkay = () => {
    if (!data.value || data.value === 0) return false;
    if (data.value < 0 || data.value > 100) {
      return false;
    }
    return true;
  };
  //chek filter before uploading
  const isFilterOkay = (filter: Filter) => {
    if (filter.column_name === "" && filter.values.length === 0) {
      return true;
    }
    if (filter.column_name !== "" && filter.values.length > 0) {
      return true;
    }
    return false;
  };
  //reset data on save or exit
  const resetData = () => {
    setData({
      type: "increase",
      column_to_update: "",
      value: 5,
      filters: [
        {
          column_name: "",
          values: [],
        },
      ],
    } as any);
  };

  const handleChange = (event: any) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  /**
   * Get list of columns from the database
   */
  const getColumnsFromDb = async () => {
    const response = await sendRequest("GET", "functions/columns/");
    const data: DataFunctionsWithFilters[] = await response.data;
    if (data) {
      setColDataForFilters(data);
    }
  };

  //add row
  const addFilterRow = () => {
    const newFilter: Filter = {
      column_name: "",
      values: [],
      index: data?.filters?.length || 0,
    };
    const newFilters: Filter[] = [...data.filters];
    newFilters.push(newFilter);
    setData({ ...data, filters: newFilters });
  };

  const getData = async () => {
    const response = await sendRequest("GET", "data");
    if (response.status === "success") {
      setCarvanData(response.data);
    } else {
      alert(response.message);
    }
  };

  const onFilterUpdate = (value: Filter) => {
    const nFilters = [...data.filters];
    const index = nFilters.findIndex((fil) => fil.index === value.index);
    nFilters[index] = value;
    setData({ ...data, filters: [...nFilters] });
   
  };
  /**
   *  cehck which columns are selected by the usser for filters
   * @returns List of Columns selected by user as filter
   */
  const getActiveColumns = () => {
    const activeColumns = [];
    for (let filter of data.filters) {
      activeColumns.push(filter.column_name);
    }
    return activeColumns;
  };

  /**
   * When user selectes a column, remove it from the list of available columns
   * To choose from the dropdown on columns
   */
  const updateColDataForFilters = () => {
    const newColumnsList = filterColumns.filter(
      (col: any) => !getActiveColumns().includes(col.column_name as string)
    );
    setColDataForFilters([...newColumnsList]);
  };

  useEffect(() => {
    resetData();
    getData();
    //getColumnsFromDb();
    setColDataForFilters(filterColumns);
    /**
     * Destructor
     */
    return () => {
      //set everything to initial state on leaving
      resetData();
    };
  }, []);

  useEffect(() => {
    updateColDataForFilters();
  }, [data.filters]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-function"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <Divider sx={{ mr: 30 }} />
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <FunctionsIcon sx={{ mr: 1, my: 0.5 }} />
          <Typography variant="h6" component="h6">
            Function
          </Typography>
        </Box>
        {/* FUNCTION TYPE */}
        <FormControl sx={{ width: 520 }} className={styles.formTextField}>
          <InputLabel id="select-function">Select Function</InputLabel>
          <Select
            labelId="select-function"
            name="type"
            value={data.type}
            label="Function"
            onChange={handleChange}
          >
            <MenuItem value="increase">Increase</MenuItem>;
            <MenuItem value="decrease">Decrease</MenuItem>;
          </Select>
        </FormControl>
        {/* FUNCTION COLUMN */}
        <FormControl className={styles.formTextField} sx={{ width: 130 }}>
          <InputLabel id="select-columns">Selet Column</InputLabel>
          <Select
            labelId="select-columns"
            name="column_to_update"
            error={error.name === "column_to_update"}
            value={data.column_to_update}
            label="Column"
            onChange={handleChange}
          >
            {columns.map((col) => {
              return (
                <MenuItem value={col.column_name}>{col.column_name}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* FUNCTION VALUE */}
        <FormControl className={styles.formTextField} sx={{ width: 380 }}>
          <TextField
            error={error.name === "value"}
            fullWidth
            name="value"
            required
            type="number"
            id="function-value"
            label="Value"
            placeholder="10%"
            value={data.value}
            onChange={(e) => {
              setData({ ...data, value: Number(e.target.value) });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <FilterAltIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Divider />
        <Box sx={{ mt: 1, display: "flex", alignItems: "flex-end" }}>
          <FilterAltIcon sx={{ mr: 1, my: 0.5 }} />
          <Typography variant="h6" component="h6">
            Filter
          </Typography>

          <Button
            sx={{ ml: "auto", mr: 4 }}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              addFilterRow();
            }}
          >
            Add Row
          </Button>
        </Box>
        {/* FUNCTION FILTERS */}
        <>
          {data.filters.map((val) => {
            return (
              <FiltersRow
                carvanData={carvanData}
                columns={colDataForFilters}
                updateFilter={(filter) => onFilterUpdate(filter)}
                index={val.index}
              />
            );
          })}
        </>
        {error.message !== "" && (
          <span className={styles.error}>{"*" + error.message}</span>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} size="small">
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={saveFunction}
          variant="contained"
          color="secondary"
        >
          Save Function
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFunctionModal;
