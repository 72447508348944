import React, { useState } from "react";
import Button from "@mui/material/Button";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import styles from "../../styles/users.module.scss";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { sendRequest } from "../../utils/api";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (isSuccess: boolean, message: string) => void;
}
const AddUserModal = ({ open, onClose, onSave }: Props) => {
  const handleClose = () => onClose();
  const [user, setUser] = useState({
    user: "",
    password: "",
    role: "basic",
  } as any);

  const [error, setError] = React.useState({
    email: false,
    password: false,
    text: "",
  });

  const onSaveNewUser = async () => {
    if (user.email === "") {
      setError({ ...error, email: true, text: "Please Fill out the form" });
      return;
    }
    if (!isValidEmail(user.email)) {
      setError({ ...error, email: true, text: "The email entered is invalid" });
      return;
    }
    if (user.password === "") {
      setError({ ...error, password: true, text: "Please fill out the form" });
      return;
    }
    if (user.password.length < 4) {
      setError({
        ...error,
        password: true,
        text: "Password too short, it should have a length of 4",
      });
      return;
    }
    const payload = JSON.stringify({
      email: user.email,
      password: user.password,
      role: user.role || "basic",
    });

    const response = await sendRequest("POST", "users", payload);
    if (response.status === "success") {
      onSave(true, response.message);
    } else {
      setError({
        ...error,
        text: `Failed To add User: ${response.message}`,
      });
    }
  };

  function onEmailChange (e: any) {
    setError({
      ...error,
      email: false,
      text: "",
    });
    var newEmail: string = e.target.value;
    const mUser = {
      email: newEmail,
      password: user.password,
      role: user.role,
    };
    setUser(mUser);
  }
  const onPasswordChange = (e: any) => {
    setError({
      ...error,
      password: false,
      text: "",
    });
    setUser({ ...user, password: e.target.value });
  };
  const onUserTypeChange = (e: any) => {
    setUser({ ...user, role: e.target.value });
  };

  function isValidEmail (email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Add User"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle sx={{ bg: "#ccc" }}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <PersonAddIcon sx={{ mr: 1, my: 0.5 }} />
          <Typography variant="h6">Add new user</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <TextField
            onChange={onEmailChange}
            required
            id="user-email"
            label="Email"
            helperText="Enter email for new user"
            placeholder="user@demo.com"
            margin="dense"
            fullWidth
            error={error.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormControl sx={{ width: "47%" }}>
            <TextField
              onChange={onPasswordChange}
              required
              id="user-password"
              label="Password"
              helperText="Enter password for new user"
              placeholder="*******"
              defaultValue=""
              margin="dense"
              fullWidth
              error={error.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "50%" }}>
            <InputLabel id="select-user-role">Select User Role</InputLabel>
            <Select
              id="user-role"
              labelId="select-user-role"
              name="role"
              value={user.role}
              label="User Role"
              onChange={onUserTypeChange}
            >
              <MenuItem value="basic">Basic</MenuItem>;
              <MenuItem value="admin">Admin</MenuItem>;
            </Select>
          </FormControl>
          <span className={styles.error}>{"*" + error.text}</span>
        </DialogContentText>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleClose} size="small">
          Cancel
        </Button>
        <Button onClick={onSaveNewUser} autoFocus variant="contained">
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddUserModal;
