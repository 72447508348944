import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerHeader from "./components/DrawerHeader";
import NavigationDrawer from "./components/NavigationDrawer";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Login from "./pages/Login";
import { adminRoutes, regularRoutes } from "./utils/routes";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Role } from "./models/types";
import ResetPassword from "./pages/ResetPassword";
import styles from './styles/common.module.scss'

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Home () {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null as unknown as User);
  const [userRole, setUserRole] = React.useState(null as unknown as Role);
  const [pageState, setPageState] = React.useState('loading')


  React.useEffect(() => {
    onAuthStateChanged(getAuth(), (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        updateUserRole(firebaseUser)
      }
      setPageState('live')
    });
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const updateUserRole = async (user: User) => {
    const tokenResults = await user.getIdTokenResult()
    const newRole = !!tokenResults.claims.admin ? 'admin' : 'basic'
    setUserRole(newRole)
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (pageState === 'loading') {
    return (<div className={styles.flexCentered}><div className={styles.loader}></div></div>)
  }
  if (!user) {
    return (
      <Router>
        <Routes>
          <Route path={'/'} element={<Login />} />
          <Route path={'/reset-password'} element={<ResetPassword />} />
          <Route path={'/login'} element={<Login />} />
        </Routes>
      </Router>
    )
  } else {
    const routes = userRole === "admin" ? adminRoutes : regularRoutes;
    return (
      <Router>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Dashboard
              </Typography>
            </Toolbar>
          </AppBar>
          <NavigationDrawer
            theme={theme}
            open={open}
            handleDrawerClose={handleDrawerClose}
            routes={routes}
          />

          <Main open={open}>
            <DrawerHeader theme={theme} />
            <Routes>
              {routes.map((route, index) => {
                return (
                  <Route key={index} path={route.path} element={<route.main />} />
                );
              })}
              {/*  */}
            </Routes>
          </Main>

        </Box>

      </Router>
    );
  }




}
