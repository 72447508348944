/**
 * @file has all the functions to read/manage EXCEL/NUMBERS files
 * @author Hamza Iqbal
 * @date 02-08-2022
 */
import { CarvanData } from "../models/types";
declare namespace XLSX {
  function read(s: any, m: any): any;
  let utils: any;
  type Sheet2JSONOpts = any;
}

/**
 *  Converts the file into json using XLXS library
 *
 * @param file File Object from the input type = file
 * @returns {CarvanData[]} data in json array from the first sheet in @param file
 */
export const excelToJson = (file: any): CarvanData[] => {

  let workbook = XLSX.read(file, { type: 'array' })
  console.log('Finished reading')
  let firstSheet = workbook.Sheets[workbook.SheetNames[0]];
  const options: XLSX.Sheet2JSONOpts = {
    skipHidden: true,
    range: 0,
  };
  const jsonSheet: CarvanData[] = XLSX.utils.sheet_to_json(firstSheet, options);
  return jsonSheet;
};
