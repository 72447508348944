import { Button, IconButton, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import AddFunctionModal, {
  FunctionData,
} from "../components/modals/AddNumericFunction";
import styles from "../styles/functions.module.scss";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { sendRequest } from "../utils/api";
import { DataFunctionsWithFilters } from "../models/types";
import ErrorAlert from "../components/ErrorAlert";
export interface APINotification {
  type: "error" | "success";
  text: string;
}
const DataFunctions = () => {
  const [isAddFunctionOpen, setAddFunctionOpen] = useState(false);
  const [notification, setNotification] = useState<APINotification>({
    type: "error",
    text: "",
  });
  const [functions, setFunctions] = useState<FunctionData[]>([]);
  const [columns, setColumns] = useState<DataFunctionsWithFilters[]>([]);

  /**
   * Getting all the functions/filters from DB
   */
  const getFunctionsFromDb = async () => {
    const response = await sendRequest("GET", "functions/");
    if (response.status === "success") {
      updateDataStrucutre(response.data);
    } else {
      setNotification({ type: "error", text: response.message });
    }
  };
  /**
   * Getting Numeric Columns from the DB
   */
  const getColumnsFromDb = async () => {
    const response = await sendRequest("GET", "functions/columns/numeric/");
    if (response.status === "success") {
      //setColumns(response.data);
    } else {
      setNotification({ type: "error", text: response.message });
    }
  };
  /**
   * Called when delete function clicked
   * @param filter filter Object to be deleted from DB
   */
  const onDeleteFilterClicked = async (filter: FunctionData) => {
    const payload = {
      function: {
        id: filter.id,
      },
    };
    const response = await sendRequest(
      "DELETE",
      "functions/",
      JSON.stringify(payload)
    );
    //if successful, reset
    if (response.status === "success") {
      setNotification({
        type: "success",
        text: "Function deleted successfuly",
      });
      getFunctionsFromDb();
    } else {
      setNotification({ type: "error", text: response.message });
    }
  };
  /**
   * Runs when a new function save request is sent to the server
   * @param isSuccess true if saved in DB successfully, else false
   *
   */
  const onSaveNewFunction = (isSuccess: boolean) => {
    if (isSuccess) {
      setAddFunctionOpen(false);
      getFunctionsFromDb();
      setNotification({
        type: "success",
        text: "Function Saved Successful",
      });
    } else {
      setNotification({
        type: "error",
        text: "Couldn't save function in the database",
      });
    }
  };
  const updateDataStrucutre = (functions: any[]) => {
    const rows: FunctionData[] = [];
    for (let fn of functions) {
      const fnId = fn.function_id;
      if (rows[fnId] === undefined || rows[fnId] === null) {
        rows[fnId] = {
          id: fnId,
          name: fn.name,
          type: fn.type,
          column_to_update: fn.column_to_update,
          value: fn.value,
          filters: [
            {
              column_name: fn.column_name,
              values: fn.values,
              index: 0,
            },
          ],
        };
      } else {
        rows[fnId].filters.push({
          column_name: fn.column_name,
          values: fn.values,
          index: 0,
        });
      }
    }
    setFunctions(rows);
  };

  useEffect(() => {
    getFunctionsFromDb();
    //getColumnsFromDb();
    //currently using only guideprice
    setColumns([{ column_name: "guideprice" }]);
  }, []);
  return (
    <>
      {notification && notification.text !== "" && (
        <ErrorAlert text={notification.text} type={notification.type} />
      )}
      {isAddFunctionOpen && (
        <AddFunctionModal
          open={isAddFunctionOpen}
          onSave={onSaveNewFunction}
          onClose={() => {
            setAddFunctionOpen(false);
          }}
          columns={columns}
        />
      )}

      <div className={styles.addFunctionButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<AddIcon />}
          onClick={() => setAddFunctionOpen(true)}
        >
          Add a new function
        </Button>
      </div>
      <Paper sx={{ p: 2, width: "100%", overflow: "hidden" }}>
        <table className={styles.functionTable}>
          <thead>
            <th>Type</th>
            <th>Value</th>
            <th>ON</th>
            <th>Filters </th>
            <th>Actions</th>
          </thead>
          <tbody>
            {functions.map((fn) => {
              return (
                <tr>
                  <td>{fn.type}</td>
                  <td>{`${fn.value}%`}</td>
                  <td>{fn.column_to_update}</td>
                  <td className={styles.filtersBox}>
                    <table>
                      {fn.filters.map((filter) => {
                        return (
                          <tr className={styles.fitlersRow}>
                            <td>{filter.column_name}</td>
                            <td>{filter.values?.toString()}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </td>
                  <td>
                    <IconButton
                      color="secondary"
                      aria-label="Delete User"
                      onClick={(e) => {
                        onDeleteFilterClicked(fn);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Paper>
    </>
  );
};
export default DataFunctions;
