/**
 * @file to create theme for mui library
 * Set all theme attribures here
 * @documentation -> https://mui.com/material-ui/customization/theming/
 */

import { ThemeOptions, createTheme } from "@mui/material";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#255CA6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#D95E40",
    },
    text: {
      primary: "#1E2445",
    },
  },
};
export default createTheme(themeOptions);
