/**
 * User can Upload any NUMBER/EXCEL file to the database.
 * Provides Interface for the user to upload file to the database
 * @author Hamza Iqbal
 * @module for @page Upload Data
 *
 */

import Button from "@mui/material/Button";
import {
  Typography,
  Box,
  Divider,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadIcon from "@mui/icons-material/Upload";
import { useEffect, useState } from "react";
import { excelToJson } from "../utils/DataReaded";
import { CarvanData } from "../models/types";
import { sendRequest } from "../utils/api";
import {
  getColumnsArrayFromData,
  getError,
  getNumberOfColumnsFromData,
  getNumberOfRowsFromData,
  getStandardColumnArray,
  isDataValid,
} from "../utils/data-validator";

const UploadData = () => {
  const [error, setError] = useState("");
  const [data, setData] = useState(null as unknown as CarvanData[]);
  const [fileName, setFileName] = useState("");
  const [dataDetails, setDataDetails] = useState({
    totalColumns: 0,
    totalRows: 0,
    columns: [] as any[],
  });
  const requiredDataDetails = {
    totalColumns: getStandardColumnArray().length,
    totalRows: 0,
    columns: getStandardColumnArray(),
  };
  const onFileChange = (e: any) => {
    if (e === null || e === undefined) {
      setError("Event Not Fired");
      return;
    }
    let file = e.target.files[0];
    if (!file) {
      setError("Please select a file to upload");
      return;
    }
    setFileName(file.name);

    let reader = new FileReader();
    reader.onload = function (event) {
      if (event === null || event === undefined) {
        return event;
      }
      console.log("onload", new Date(), file);
      let data = event!.target!.result;
      data = new Uint8Array(data as ArrayBuffer);
      const json: CarvanData[] = excelToJson(data);
      setData(json);
      setDataDetails({
        totalColumns: getNumberOfColumnsFromData(json),
        columns: getColumnsArrayFromData(json) ,
        totalRows: getNumberOfRowsFromData(json),
      });
      e!.target!.value = null;
    };
    reader.readAsArrayBuffer(file);
  };

  /**
   *  File data is converted to JSON and uploaded to the server
   * @returns {Promise<void>}
   */
  const onFileUpload = async (): Promise<void> => {
   
    if (!data || data.length === 0) {
      setError("*Please select a file to upload");
      return;
    }
    if (!isDataValid(data)) {
      setError(getError(data));
      return;
    }

    let dataToSend = "";
    try {
      dataToSend = JSON.stringify(data);
    } catch (error) {
      setError("*Data Invalid! Please Check details of the data");
      throw new Error(`${error}`);
    }
    if (data.length > 0) {
      const response = await sendRequest("POST", "data/upload", dataToSend);
      if (response.status === "success") {
        setData(null as unknown as CarvanData[]);
        alert("Data Uploaded Successfuly");
      } else {
        alert(response.message);
      }
    }
  };
  // COMPONENT DID MOUNT
  useEffect(() => {
    // Update the document title using the browser API
    const script = document.createElement("script");
    script.src = "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js";
    script.async = false;
    document.body.appendChild(script);
  });

  useEffect(() => {
    setError("");
    
  }, [data]);
  return (
    <>
      <Typography component="h1" variant="h5">
        <h3>Upload New Data</h3>
      </Typography>
      <Box>
        <Button
          variant="outlined"
          component="label"
          startIcon={<AttachFileIcon />}
        >
          Select File To Upload
          <input hidden type="file" onChange={onFileChange} />
        </Button>

        <Button
          onClick={onFileUpload}
          variant="contained"
          sx={{ margin: "20px" }}
          endIcon={<UploadIcon />}
        >
          Upload
        </Button>
      </Box>
      {data && <span className="file-name">{fileName}</span>}
      <div>{error}</div>
      <Divider sx={{ margin: "20px" }} />

      {data && (
        <Grid container>
          <Grid item xs>
            <Card sx={{ minWidth: 175 }}>
              <CardContent>
                <Box className="data-details">
                  <h4> Required</h4>

                  <div>
                    Columns :{" "}
                    <strong>{requiredDataDetails.columns.join(", ")}</strong>
                  </div>

                  <div>
                    Total Columns :
                    <strong> {requiredDataDetails.totalColumns}</strong>
                  </div>

                  <div>
                    Total Rows : <strong>--</strong>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Divider orientation="vertical" flexItem>
            VS
          </Divider>
          <Grid item xs>
            <Card sx={{ minWidth: 175 }}>
              <CardContent>
                <Box className="data-details">
                  <h4> Provided</h4>

                  <div>
                    Columns : <strong>{dataDetails.columns.join(", ")}</strong>
                  </div>

                  <div>
                    Total Columns :<strong> {dataDetails.totalColumns}</strong>
                  </div>

                  <div>
                    Total Rows : <strong>{dataDetails.totalRows}</strong>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      )}
    </>
  );
};
export default UploadData;
