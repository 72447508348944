import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  Divider,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment,
  FormControl,
  DialogActions,
  Button,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useEffect, useState } from "react";
import { sendRequest } from "../../utils/api";

interface Props {
  open: boolean;
  handleClose: () => void;
  onSave: (data: any) => void;
  oldGuidePrice: any;
  id: any;
}
const EditDataModal = ({
  open,
  handleClose,
  id,
  oldGuidePrice,
  onSave,
}: Props) => {
  const [guidePrice, setPrice] = useState(oldGuidePrice);

  useEffect(() => {
    setPrice(oldGuidePrice);
  }, [oldGuidePrice]);
  const onSaveNewData = async () => {
    if (guidePrice === oldGuidePrice) {
      //nothing to save
    } else {
      const payload = {
        id: id,
        newGuidePrice: guidePrice,
      };
      const response = await sendRequest(
        "PUT",
        "data/guideprice",
        JSON.stringify(payload)
      );
      //if successful, reset
      if (response.status === "success") {
        onSave({
          type: "success",
          text: "Guide Price Updated successfuly",
        });
      } else {
        onSave({ type: "error", text: response.message });
      }
    }
  };
  const onGuidePriceChange = (e: any) => {
    setPrice(e.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Edit Data"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle sx={{ bg: "#ccc" }}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <EditIcon sx={{ mr: 1, my: 0.5 }} />
          <Typography variant="h6">Edit Data</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <TextField
            required
            id="column"
            label="Column to Update"
            placeholder="GuidePrice"
            margin="dense"
            value="Guide Price"
            contentEditable={false}
            fullWidth
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormControl sx={{ width: "100%" }}>
            <TextField
              required
              id="guide-price"
              label="New value"
              helperText="Enter New Value to be saved"
              placeholder="13,000"
              defaultValue={guidePrice}
              value={guidePrice}
              onChange={onGuidePriceChange}
              type="number"
              margin="dense"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyPoundIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleClose} size="small">
          Cancel
        </Button>
        <Button
          onClick={onSaveNewData}
          autoFocus
          variant="contained"
          disabled={guidePrice <= 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditDataModal;
