import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DataFunctionsWithFilters } from "../models/types";

import styles from "../styles/functions.module.scss";
import { Filter } from "./modals/AddNumericFunction";
interface Props {
  carvanData: any[];
  columns: DataFunctionsWithFilters[];
  updateFilter: (filer: any) => void;
  index: number;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const FiltersRow = ({ carvanData, columns, index, updateFilter }: Props) => {
  const [data, setData] = useState<Filter>({
    column_name: "",
    values: [] as any,
    index: index,
  });

  const [columnValues, setColumnValues] = useState([] as any);
  const [dataColumns, setDataColumns] = useState(columns);
  const handleChange = (event: SelectChangeEvent<typeof data.values>) => {
    const {
      target: { value },
    } = event;
    setData({
      ...data,
      values: typeof value === "string" ? value.split(",") : value,
    });
  };

  const onColumnChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setData({ ...data, column_name: value });
    //get values for this column
    updateValues(value);
  };

  //2 ways, get from the data object or call API
  //using data to filter unique items
  const updateValues = async (columnName: string) => {
    //from carvandata
    const uniqueValues: any = [];
    for (let item of carvanData) {
      if (!uniqueValues.includes(item[columnName])) {
        uniqueValues.push(item[columnName]);
      }
    }
    setColumnValues(uniqueValues.sort());
  };
  useEffect(() => {
    setData({
      column_name: "",
      values: [] as any,
      index: index,
    });
    return () => {
      //distructor
      //set everything to zero
      setData({
        column_name: "",
        values: [] as any,
        index: 0,
      });
    };
  }, []);
  /**
   * When the value of columns change, add the currently selected column to the list
   */
  useEffect(() => {
    const cols: any = [...columns];

    const currentColumn = columns?.find(
      (col) => col.column_name === data.column_name
    );
    if (data.column_name !== "" && currentColumn === undefined) {
      cols.push({ column_name: data.column_name });
    }
    setDataColumns([...cols]);
  }, [columns]);
  useEffect(() => {
    updateFilter(data);
  }, [data]);
  return (
    <>
      {" "}
      <FormControl className={styles.formTextField} sx={{ width: 130 }}>
        <InputLabel id="select-function">
          Select column for the filter
        </InputLabel>
        <Select
          labelId="select-function"
          name="column_name"
          value={data.column_name}
          label="Function"
          onChange={onColumnChange}
        >
          {dataColumns.map((col) => {
            return (
              <MenuItem value={col.column_name}>{col.column_name}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className={styles.formTextField} sx={{ width: 380 }}>
        <InputLabel id="column-values">Values</InputLabel>
        <Select
          labelId="column-values"
          id="vals"
          multiple
          value={data.values}
          onChange={handleChange}
          input={<OutlinedInput label="Value" />}
          MenuProps={MenuProps}
        >
          {columnValues.map((val: any) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default FiltersRow;
