/**
 * @author Hamza Iqbal
 * @module for @page Users
 * [ADMIN ONLY]
 */
import { Paper, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { User } from "../models/types";
import { sendRequest } from "../utils/api";
import AddUserModal from "../components/modals/AddUser";
import { useEffect, useState } from "react";
import styles from "../styles/users.module.scss";
import { APINotification } from "./DataFunctions";
import ErrorAlert from "../components/ErrorAlert";
const Users = () => {
  const [isAddUserOpen, setAddUserOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [notification, setNotification] = useState<APINotification>({
    type: "error",
    text: "",
  });

  const onDeleteUserClicked = (user: User) => {
    if (user && user.uid) {
      deleteUser(user.uid);
    }
  };
  const deleteUser = async (userId: number) => {
    const payload = JSON.stringify({ uid: userId });
    const response = await sendRequest("DELETE", "users", payload);
    if (response.status === "success") {
      setNotification({ type: "success", text: response.message });
      getUsersFromDb();
    } else {
      setNotification({ type: "error", text: response.message });
    }
  };
  const getUsersFromDb = async () => {
    const response = await sendRequest("GET", "users/");
    if (response.status === "success") {
      const data: User[] = await response.data;
      setUsers(data);
    } else {
      setNotification({ type: "error", text: response.message });
    }
  };
  /**
   *
   */
  const onSaveUser = (isSuccess: boolean, message: string) => {
    if (isSuccess) {
      getUsersFromDb();
      setNotification({ type: "success", text: message || "User saved" });
      onModalClosed();
    } else {
      setNotification({ type: "error", text: message || "Error Saving User" });
    }
  };
  //runs on reload
  useEffect(() => {
    getUsersFromDb();
  }, []);
  const onModalClosed = () => {
    setAddUserOpen(false);
  };

  return (
    <>
      {notification && notification.text !== "" && (
        <ErrorAlert text={notification.text} type={notification.type} />
      )}
      <AddUserModal
        open={isAddUserOpen}
        onClose={onModalClosed}
        onSave={onSaveUser}
      />

      <div className={styles.addUserButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<AddIcon />}
          onClick={() => setAddUserOpen(true)}
        >
          Add a new user
        </Button>
      </div>
      <Paper sx={{ p: 2, width: "100%", overflow: "hidden" }}>
        <table className={styles.usersTable}>
          <thead>
            <th>uid</th>
            <th>Email</th>
            <th>Type</th>
            <th>Actions</th>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr className={styles.usersTableRow}>
                  <td>{user.uid}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <IconButton
                      color="secondary"
                      aria-label="Delete User"
                      onClick={(e) => {
                        onDeleteUserClicked(user);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Paper>
    </>
  );
};
export default Users;
