import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef, useEffect, useState } from "react";

interface Props {
  text: string;
  title?: string;
  type?: "error" | "success";
}
const ErrorAlert = ({ text, title, type }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    if (text && text !== "") setIsOpen(true);
  }, [text]);
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => setIsOpen(false)}
    >
      <Alert severity={type || "error"} sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  );
};
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default ErrorAlert;
