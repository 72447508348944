/**
 * User can View Data in the database.
 * Provides Interface for the user to view the data stored in DB
 * @author Hamza Iqbal
 * @module for @page DataTable
 * Uses @transtack table headless to view data in table
 *
 */

import { useEffect, useMemo, useState } from "react";
import { CarvanData, Role } from "../models/types";
import { rankItem } from "@tanstack/match-sorter-utils";
import styles from "../styles/dataTable.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
  PaginationState,
} from "@tanstack/react-table";
import defaultColumns from "../utils/TableData";
import { Box, Button, IconButton, Table } from "@mui/material";
import SearchField from "../components/SearchField";
import { TableHeaderCell } from "../components/TableHeaderCell";
import { sendRequest } from "../utils/api";
import Pagenation from "../components/Pagenation";
import ErrorAlert from "../components/ErrorAlert";
import { APINotification } from "./DataFunctions";
import EditDataModal from "../components/modals/EditDataModal";
import { getAuthStateChange } from "../utils/firebase";
import { User } from "firebase/auth";
import DeleteRowsButton from "../components/DeleteRowsButton";
export interface Props {
  /** The user object */
  user: any;
}

const DataTable: React.FC<Props> = (props) => {
  const [notification, setNotification] = useState<APINotification>({
    type: "error",
    text: "",
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const columns = useMemo<ColumnDef<CarvanData, any>[]>(
    () => defaultColumns,
    []
  );
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [data, setData] = useState([] as CarvanData[]);
  const [json, setJson] = useState([] as CarvanData[]);
  const [rowSelection, setRowSelection] = useState({})
  const [userRole, setUserRole] = useState<Role>('basic')
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editRow, setEditRow] = useState({} as any);
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  /**Creating a table object */
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    state: {
      rowSelection,
      globalFilter,
      pagination,
    },

    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    //get data
    getDataFromServer();
    getAuthStateChange(async (user: User) => {
      const tokenResults = await user.getIdTokenResult()
      const newRole = !!tokenResults.claims.admin ? 'admin' : 'basic'
      setUserRole(newRole)
    })
  }, []);

  const getDataFromServer = async () => {
    const response = await sendRequest("GET", "data");
    if (response.status === "success") {

      setData(response.data);
      setJson(response.data);
    } else {
      alert(response.message);
    }
  };
  useEffect(() => {
    if (globalFilter === "") {
      setData(json);
    }
    setData(
      json.filter((row) => {
        let key: keyof CarvanData;
        for (key in row) {
          if (
            row[key]
              .toString()
              .toLowerCase()
              .includes(globalFilter.toLowerCase())
          )
            return true;
        }
      })
    );
  }, [globalFilter, json]);

  /**
   * Called when delete function clicked
   * @param dataRow filter Object to be deleted from DB
   */
  const onDeleteFilterClicked = async (dataRow: any) => {
    setNotification({
      type: "error",
      text: "",
    });
    const payload = {
      row: {
        id: dataRow.id,
      },
    };

    const response = await sendRequest(
      "DELETE",
      "data/row",
      JSON.stringify(payload)
    );
    //if successful, reset
    if (response.status === "success") {
      setNotification({
        type: "success",
        text: "Data row deleted successfuly",
      });
      getDataFromServer();
    } else {
      setNotification({ type: "error", text: response.message });
    }
  };

  /**
   * Called when edit icon clicked
   * @param dataRow
   */
  const onEditClicked = async (dataRow: any) => {
    //open Modal
    setEditRow(dataRow);
    setIsEditModalOpen(true);
  };
  const onSaveNewData = (data: any) => {
    setNotification(data);
    getDataFromServer();
    setIsEditModalOpen(false);

  };
  const bulkDelete = async () => {
    const payload = {
      rows: Object.keys(rowSelection).map((id: any) => json[id].id)
    };
    const response = await sendRequest(
      "DELETE",
      "data/rows",
      JSON.stringify(payload)

    );
    //if successful, reset
    if (response.status === "success") {
      setNotification({
        type: "success",
        text: "Data row deleted successfuly",

      });
      setRowSelection({})
      getDataFromServer();
    } else {
      setNotification({ type: "error", text: response.message });
    }
  }

  return (
    <>
      {notification && notification.text !== "" && (
        <ErrorAlert text={notification.text} type={notification.type} />
      )}
      <EditDataModal
        open={isEditModalOpen}
        handleClose={function (): void {
          setIsEditModalOpen(false);
        }}
        oldGuidePrice={editRow.guideprice}
        id={editRow.id}
        onSave={onSaveNewData}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            width: 500,
            maxWidth: "100%",
          }}
        >
          <SearchField setGlobalFilter={setGlobalFilter} />
        </Box>
        {
          Object.keys(rowSelection).length > 0 && 
          <DeleteRowsButton
            onClick={bulkDelete}
            numRowsToDelete={Object.keys(rowSelection).length}
            totalRows={table.getPreFilteredRowModel().rows.length}
          />

        }
        <table className={styles.dataTable}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={1} rowSpan={1}>
                      <TableHeaderCell header={header} />
                    </th>
                  );
                })}
                {userRole && userRole === "admin" && <td>Act</td>}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                  {userRole && userRole === "admin" && (
                    <td >
                      <div className={styles.actionTd}>
                        <IconButton
                          color="secondary"
                          aria-label="Delete Row"
                          onClick={(e) => {
                            onDeleteFilterClicked(row.original);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          aria-label="Delete Row"
                          onClick={(e) => {
                            onEditClicked(row.original);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>


                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagenation table={table} />
      </Box>
    </>
  );
};

export default DataTable;
