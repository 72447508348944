import React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material/';

import DeleteIcon from "@mui/icons-material/Delete";
import { fontSize } from '@mui/system';
interface Props {
    onClick: () => void;
    numRowsToDelete: number;
    totalRows: number;
}


const DeleteRowsButton = ({ onClick, numRowsToDelete, totalRows }: Props) => {
    return (
        <Box display="flex" alignItems="center">
            <Button onClick={onClick} variant='outlined'>
                <DeleteIcon color='warning' />
                <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                    delete {numRowsToDelete} row(s)
                </Typography>
            </Button>
            <Typography variant='subtitle2' style={{marginLeft : '8px'}}>&nbsp;({totalRows} total)</Typography>
        </Box>
    );
};

export default DeleteRowsButton;
