import { Avatar, Divider, Drawer, IconButton } from "@mui/material";
import DrawerHeader from "./DrawerHeader";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Link } from "react-router-dom";

interface Props {
  theme: any;
  open: boolean;
  handleDrawerClose: any;
  routes: any;
}
const drawerWidth = 240;

const NavigationDrawer = ({
  theme,
  handleDrawerClose,
  open,
  routes,
}: Props) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader theme={theme}>
        <Avatar
          sx={{ m: 1, width: 180, height: 50 }}
          variant="square"
          alt="Logo"
          src={require("../logo.webp")}
        ></Avatar>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />

      {routes.map((route: any, index: number) => (
        <>
          <Link to={route.path}>
            <route.sidebar />
          </Link>
        </>
      ))}
    </Drawer>
  );
};

export default NavigationDrawer;
