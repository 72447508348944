/**
 * @author Hamza Iqbal
 * @module for @page Lgout
 * Removes the User Object and Redirects to the login page
 */

import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    signOut(getAuth()).then(() => {
      window.location.pathname = "/";
    })
  });
  return <>{ }</>;
};
export default Logout;
