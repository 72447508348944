/**
 * @file Used to create ColumnDefiniton to be used with @tanstack table
 * @documentation -> https://tanstack.com/query/v4
 */

import { ColumnDef } from "@tanstack/react-table";
import { CarvanData } from "../models/types";
import { getColumnDefinitonArray } from "./carvan-data-utils";

const defaultColumns: ColumnDef<CarvanData>[] = getColumnDefinitonArray();

export default defaultColumns;
