/**
 * @author Hamza Iqbal
 * @module for @page Login
 */
import * as React from "react";
import Button from "@mui/material/Button";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,

} from "@mui/material";

import ErrorAlert from "../components/ErrorAlert";
import { resetPassword } from "../utils/firebase";

const ResetPassword = () => {
  const [error, setError] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
    if (error !== "") {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  }, [error]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string
    try {
      resetPassword(email)
      window.location.pathname = "/";
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {openDialog && <ErrorAlert text={error} />}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, width: 128, height: 128 }}
          variant="square"
          alt="Logo"
          src={require("../logo-small.webp")}
        ></Avatar>
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Reset Email
          </Button>

        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
