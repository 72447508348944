import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

interface Props {
  itemTitle: string;
  path: string;
  icon: any;
}
const NavigtionItem = ({ itemTitle, path, icon }: Props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>{icon}</ListItemIcon>
        <Typography>
          <ListItemText primary={itemTitle} />
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};
export default NavigtionItem;
