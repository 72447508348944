
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, User } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyD1-9HD6of2FGvWWd5ZM62sbpHJuUELPlk",
  authDomain: "webuyanymotarcarvan.firebaseapp.com",
  projectId: "webuyanymotarcarvan",
  storageBucket: "webuyanymotarcarvan.appspot.com",
  messagingSenderId: "882980543619",
  appId: "1:882980543619:web:d8c4ae81d73732d5170fb6",
  measurementId: "G-1VTEDEKSS9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


export const signInUserWithEmailPass = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
export const getAuthenticatedUser = () => {
  const auth = getAuth();
  return auth.currentUser;

}
export const getIdTokenForAuthUser = (user: User) => {
  return user.getIdToken()
}
export const getAuthStateChange = (callBackFunction: any) => {
  onAuthStateChanged(auth, callBackFunction)
}

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(getAuth(), email)
    return true;
  } catch (error: any) {
    throw new Error(error)
  }

}