import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Table } from "@tanstack/react-table";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

interface Props {
  table: Table<any>;
}

const Pagenation = ({ table }: Props) => {
  return (
    <Box>
      <IconButton
        aria-label="first-page"
        color="secondary"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        aria-label="previous"
        color="secondary"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        aria-label="previous"
        color="secondary"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        aria-label="previous"
        color="secondary"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <LastPageIcon />
      </IconButton>
      <Box className="pagnation-text">
        Page
        <span>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </span>
      </Box>
      <Box>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" margin="dense">
          <TextField
            type="number"
            label="Go To Page"
            size="small"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" margin="dense">
          <InputLabel id="no-of-pages">Pages</InputLabel>
          <Select
            labelId="no-of-pages"
            id="no-of-pages-select"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                {" "}
                Show {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
export default Pagenation;
