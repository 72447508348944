/**
 * @file contains routes for both Admin and Regular User
 * These routes are for use with React-Router
 * Each user type will have a RoutesType Array with it's defined routes
 */

import NavigtionItem from "../components/NavigationItem";
import DataTable from "../pages/DataTable";
import TableRowsIcon from "@mui/icons-material/TableRows";
import UploadIcon from "@mui/icons-material/Upload";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import FunctionsIcon from "@mui/icons-material/Functions";
import DataFunctions from "../pages/DataFunctions";
import UploadData from "../pages/UploadData";
import Users from "../pages/Users";
import { Navigate } from "react-router-dom";
import Logout from "../pages/Logout";
import { getAuthenticatedUser } from "./firebase";
/**
 * Routes for admin
 */
export const adminRoutes = [
  {
    path: "/",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Data Table"}
        path={"/data"}
        icon={<TableRowsIcon />}
      />
    ),
    main: () => <DataTable user={undefined} />,
  },

  {
    path: "/upload",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Upload Data"}
        path={"/upload"}
        icon={<UploadIcon />}
      />
    ),
    main: () => (
      <UploadData />
    ),
  },
  {
    path: "/users",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Users"}
        path={"/users"}
        icon={<PeopleIcon />}
      />
    ),
    main: () => <Users />,
  },
  {
    path: "/functions",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Functions"}
        path={"/functions"}
        icon={<FunctionsIcon />}
      />
    ),
    main: () => <DataFunctions />,
  },
  {
    path: "/logout",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Logout"}
        path={"/logout"}
        icon={<LogoutIcon />}
      />
    ),
    main: () => <Logout />,
  },
];
/**
 * Routes for Regular User
 */
export const regularRoutes = [
  {
    path: "/",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Data Table"}
        path={"/data"}
        icon={<TableRowsIcon />}
      />
    ),
    main: () => <DataTable user={undefined} />,
  },
  {
    path: "/logout",
    exact: true,
    sidebar: () => (
      <NavigtionItem
        itemTitle={"Logout"}
        path={"/logout"}
        icon={<LogoutIcon />}
      />
    ),
    main: () => <Logout />,
  },
];

/**
 * protects regular user from accessing admin routes
 * Add Protected routes to all ADMIN ONLY routes
 *
 * @param param0 user tye
 * @returns provided children
 */
const ProtectedRoute = ({ children }: any) => {
  return <></>
};
