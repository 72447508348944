import { flexRender, Header } from "@tanstack/react-table";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
interface Props {
  header: Header<any, unknown>;
}
export const TableHeaderCell = ({ header }: Props) => {
  const getClassName = () => {
    return header.column.getCanSort()
      ? "cursor-pointer select-none header-cell"
      : "header-cell";
  };
  const onClickColumnHeader = () => {
    return header.column.getToggleSortingHandler();
  };
  const getCellData = () => {
    return flexRender(header.column.columnDef.header, header.getContext());
  };
  const getCellSortingIcons = () => {
    return (
      {
        asc: <ArrowDropUpIcon />,
        desc: <ArrowDropDownIcon />,
      }[header.column.getIsSorted() as string] ?? (
        <UnfoldMoreIcon className="opacity-02 cursor-pointer" />
      )
    );
  };
  return (
    <div className={getClassName()} onClick={onClickColumnHeader()}>
      {getCellData()}
      {getCellSortingIcons()}
    </div>
  );
};
