import { carvanDataColumns } from "./carvan-data-utils";

/**
 * @file to validate the data in the file before uploading to the server
 * @author Hamza Iqbal -> 13besehiqbal@seecs.edu.pk
 *
 */
const validateData = (data: any) => {};
export const getError = (data: any) =>{
  if (!data || data.length === 0) {   
    return 'Data not provided';
  }
  const columnsLength = Object.keys(carvanDataColumns).length;
  const dataColumnsLength = getNumberOfColumnsFromData(data);
  if (dataColumnsLength > columnsLength) {
    return `Extra Columns Provided, Requried is: ${columnsLength}` ;
  }
  if (dataColumnsLength < columnsLength) {
    return `Less Columns Provided, Requried is: ${columnsLength}` ;
  }
  
  //match each column
  for (let key in carvanDataColumns) {
    if (!getColumnsArrayFromData(data).includes(key)) {      

       return `Missing Column ${key}` ;
    }
  }
  return 'Something went wrong'

}
const isDataValid = (data: any) => {
  //check number of columns
  if (!data || data.length === 0) {   
    return false;
  }
  const columnsLength = Object.keys(carvanDataColumns).length;
  const dataColumnsLength = getNumberOfColumnsFromData(data);
  if (dataColumnsLength !== columnsLength) {
    return false;
  }
  
  //match each column
  for (let key in carvanDataColumns) {
    if (!getColumnsArrayFromData(data).includes(key)) {      
      return false;
    }
  }

  //if every check passes, return true
  return true;
};
export const getColumnsArrayFromData = (data: any) => {
  const columns: any[] = [];
  for (let item of data) {
    let columnsInItem = Object.keys(item);
    for (let col of columnsInItem) {
      if (!columns.includes(col)) columns.push(col);
    }
  }
  return columns;
};
export const getNumberOfColumnsFromData = (data: any) => {
  return getColumnsArrayFromData(data).length;
};
export const getStandardColumnArray = () => {
  return Object.keys(carvanDataColumns);
};
export const getNumberOfRowsFromData = (data: any) => {
  return data?.length || 0;
};
export { validateData, isDataValid };
