import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
interface Props {
  setGlobalFilter: (value: string) => any;
}
const SearchField = ({ setGlobalFilter }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setGlobalFilter(searchValue);
  }, [searchValue]);
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
      fullWidth
      sx={{
        margin: "20px 0 20px 0",
      }}
      label="Seach data in table.."
      defaultValue="Hello World"
      value={searchValue ?? ""}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search all columns..."
    />
  );
};
export default SearchField;
