import styled from "@emotion/styled";

interface Props {
  theme: any;
}
const DrawerHeader = styled("div")(({ theme }: Props) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
export default DrawerHeader;
