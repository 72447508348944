/**
 * @author Hamza Iqbal
 * @module for @page Login
 */
import * as React from "react";
import Button from "@mui/material/Button";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Link,
} from "@mui/material";

import ErrorAlert from "../components/ErrorAlert";
import { signInUserWithEmailPass } from "../utils/firebase";
import styles from '../styles/common.module.scss'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Login = () => {
  const [error, setError] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
    if (error !== "") {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  }, [error]);
  /**
   * Sends Request to sign in when user clicks on Sign In
   *  Saves user in LocalStorage
   *
   * @param event click event
   */
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const authObject = {
      email: data.get("email") as string,
      password: data.get("password") as string,
    };
    try {
      await signInUserWithEmailPass(authObject.email, authObject.password)
      window.location.pathname = "/";
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {openDialog && <ErrorAlert text={error} />}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, width: 128, height: 128 }}
          variant="square"
          alt="Logo"
          src={require("../logo-small.webp")}
        ></Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/reset-password" variant="body2" className={styles.classHover}>
                Forgot password?
              </Link>
            </Grid>

          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
