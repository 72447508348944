/**
 * @file Manages carvan data that goes into display
 * @author Hamza Iqbal
 * @exports {array} ColumnsDefArray
 */
import IndeterminateCheckbox from "../components/TableCheckbox";
import { CURRENCY } from "./constants";
import { Row } from '@tanstack/react-table';

export const carvanDataColumns = {

  Date: { propertyName: "date" },
  Year: { propertyName: "year" },
  Reg: { propertyName: "reg" },
  Make: { propertyName: "make" },
  Model: { propertyName: "model" },
  Miles: { propertyName: "miles" },
  Gearbox: { propertyName: "gearbox" },
  "Condition Issues": { propertyName: "conditionissues" },
  Layout: { propertyName: "layout" },
  Spec: { propertyName: "spec" },
  Paid: { propertyName: "paid" },
  Sold: { propertyName: "sold" },
  Profit: { propertyName: "profit" },
  "Further Notes": { propertyName: "furthernotes" },
  "Guide Price (Without Condition Issues)": { propertyName: "guideprice" },
};
/**
 *
 * @returns {any[]} array of defination of all columns in table to display
 */
export const getColumnDefinitonArray = (): any[] => {
  const colDef = [];
  //adding checkbox col
  colDef.push({
    id: 'select',
    accessorKey: 'Select',
    enableSorting: false,

    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  })
  for (let col in carvanDataColumns) {

    colDef.push(createColumnData(col));
  }
  return colDef;
};
/**
 *  Server return date in ZULO format, coverting to required formal
 *
 * @param zuloDate {string} returned by the server
 * @returns string date in MMM-YYYY format
 */
const getFormattedDate = (zuloDate: string) => {
  return new Date(zuloDate).toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });
};

/**
 *  creates an object to use as column data for @transtack table object
 *
 * @param col {strin} id (key) of the col to get data
 * @returns {{}} an object of column Definition
 */
const createColumnData = (col: string): {} => {
  /**
   * add cases if any column has to be treated before presing data
   */
  const key =
    carvanDataColumns[col as keyof typeof carvanDataColumns].propertyName;
  let columnObject = {};
  switch (col) {
    case 'Date':
      columnObject = {
        accessorKey: key,
        sortingFn: dateSortingFn,
        header: () => <span>{col}</span>,
        cell: (props: any) => <span>{props?.getValue()}</span>,
      };
      break;
   
    case "Profit":
    case "Sold":
    case "Paid":
    case "Guide Price (Without Condition Issues)":
      columnObject = {
        accessorKey: key,
        header: () => <span>{col}</span>,
        cell: (props: any) => (
          <span>{addCurrencyToValue(props?.getValue())}</span>
        ),
      };
      break;
    case "Miles":
      columnObject = {
        accessorKey: key,
        header: () => <span>{col}</span>,
        cell: (props: any) => (
          <span>{props?.getValue()?.toLocaleString()}</span>
        ),
      };
      break;
    default:
      columnObject = {
        accessorKey: key,
        header: () => <span>{col}</span>,
        cell: (props: any) => <span>{props?.getValue()}</span>,
      };
      break;
  }
  return columnObject;
};
/**
 * Gets currency saved in constants
 * @returns {string} currency to be used with data
 */
const getCurrency = (): string => {
  return CURRENCY;
};
/**
 * Converts a number into currency
 * @param value value in numbers
 * @returns {string} value with currency symbol
 */
const addCurrencyToValue = (value: number | string): string => {
  if (value === null || value === undefined) return "0";
  if (typeof value !== "number") {
    value = Number(value);
    if (Number.isNaN(value)) return "-";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: getCurrency(),
  }).format(value);
};



const compareDates = (date1: Date, date2: Date): number => {
  if (date1 < date2) {
    return -1;
  }
  if (date1 > date2) {
    return 1;
  }
  return 0;
};

const dateSortingFn: any = (rowA :any , rowB: any, columnId : any) => {
 
  const dateA = new Date(rowA.original[columnId]);
  const dateB = new Date(rowB.original[columnId]);
  return compareDates(dateA, dateB);
};