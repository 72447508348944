/**
 * @module API communicate with server
 *
 */

import { ServerResponse } from "../models/types";
import { API_URL } from "./constants";
import { getAuthenticatedUser, getIdTokenForAuthUser } from "./firebase";
/**
 *
 * @param method GET POST PUT DELETE
 * @param path 'user/admin/'
 * @param body JSON
 * @returns
 */
export const sendRequest = async (
  method: string,
  path: string,
  body?: any
): Promise<ServerResponse> => {
  const user = getAuthenticatedUser()
  if (user !== null) {
    const token = await getIdTokenForAuthUser(user);
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    };
    const response = await fetch(`${API_URL}/${path}`, requestOptions);
    let jsonResponse = {} as ServerResponse;
    if (response) {
      if (response.ok) {
        jsonResponse = await response.json();
      } else {
        const responseText = await response.text();
        jsonResponse.code = response.status;
        jsonResponse.message = responseText;
        jsonResponse.status = "error";
      }
    } else {
      jsonResponse.code = 503;
      jsonResponse.message = "No Response From Server";
      jsonResponse.status = "error";
    }
    return jsonResponse;
  } else {
    return {
      code: 503,
      message: "No Response From Server",
      status: "error"
    };
  }
};

